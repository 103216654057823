/* Colors Neuhaus */
/* Fonts Neuhaus */
.mainbanner-image-component {
  margin: 0;
  width: 100%;
  position: relative; }

.mainbanner-common-image-component {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--focal-point-x) var(--focal-point-y);
     object-position: var(--focal-point-x) var(--focal-point-y); }

.mainbanner-tile-image {
  height: 485px; }
  @media (max-width: 991.98px) {
    .mainbanner-tile-image {
      height: 50vw; } }

.mainbanner-container .mainbanner-image-heading-container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem; }
  @media (min-width: 769px) {
    .mainbanner-container .mainbanner-image-heading-container {
      position: absolute;
      top: 0; } }

@media (min-width: 992px) {
  .mainbanner-container .mainbanner-text-container {
    padding-right: 40px;
    padding-left: 40px; } }

.glider-banner.glider::-webkit-scrollbar {
  width: 0;
  height: 0; }

.carousel-banner .glider {
  scrollbar-width: none; }
  .carousel-banner .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

@media (min-width: 769px) {
  .carousel-banner .glider-track {
    max-height: 480px; } }

.carousel-banner .banner-dots-container {
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin: 0 auto 1rem;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 769px) {
    .carousel-banner .banner-dots-container {
      position: absolute;
      margin-bottom: 3rem;
      -webkit-box-pack: normal;
          -ms-flex-pack: normal;
              justify-content: normal; } }
  @media (min-width: 992px) {
    .carousel-banner .banner-dots-container {
      padding-right: 40px;
      padding-left: 40px; } }
  @media (min-width: 1680px) {
    .carousel-banner .banner-dots-container {
      max-width: 1680px; } }

.carousel-banner .banner-dots {
  margin: 0; }

@media (min-width: 769px) {
  .banner-text {
    max-width: 33%; } }

/* Recolor white items of the banner for mobile */
.pd-title-recolor {
  color: #fff; }
  @media (max-width: 543.98px) {
    .pd-title-recolor {
      color: #503223; } }

.pd-text-recolor {
  color: #fff; }
  @media (max-width: 543.98px) {
    .pd-text-recolor {
      color: #7c655a; } }

@media (max-width: 543.98px) {
  .btn-white.pd-button-recolor {
    background-color: #cf7c58;
    color: #fff; }
  .btn-outline-white.pd-button-recolor {
    border-color: #cf7c58;
    color: #cf7c58; }
  .btn-alternate-white.pd-button-recolor {
    color: #cf7c58; }
    .btn-alternate-white.pd-button-recolor::after {
      background-color: #cf7c58; } }
